<template>
	<div
		class="cart-wr"
		:class="{'cart-wr__loading': cartLoading}"
	>
		<div class="cart-left">
			<RestaurantInfo
				class="cart__restaurant-info"
				@change="$emit('restChange',true)"
				:name="curRest.name || ''"
				:ogrn="curRest.ogrn || ''"
				:inn="curRest.inn || ''"
			/>
			<CartHeader
				cartPage
				:style="{marginBottom: 0}"
			/>
			<BlockPreloader
				class="cart-left__preloader"
				v-if="cartLoading"
			/>
			<template v-else>
				<template v-if="cartProductsShow">
					<CartProducts
						class="cart__products"
						cartPage
					/>
					<div
						class="cart__btns-wr"
						:style="{'justify-content': 'space-between'}"
					>
						<button
							v-if="currentBasket?.collective"
							@click="togglePopup('deleteBasket')"
							class="cart-wr__btn cart-empty__btn-exit"
						>
							Выйти из корзины
						</button>
						<button
							@click="redirectBasedOnTimeRange(currentBasket?.timeRange || 'lunch')"
							class="cart-wr__btn"
						>
							Перейти в меню
						</button>
					</div>
				</template>
				<div
					class="cart-empty"
					v-else-if="isMyOrder ? !myCartDetails.count : !cartProductsCount"
				>
					<img
						src="@/assets/img/cartEmpty.svg"
						alt="Cart empty"
					/>
					<div class="cart-empty__title">Корзина пока пуста.</div>
					<div class="cart-empty__descr">
						<span class="cart-empty__subtitle">Добавьте что-нибудь из нашего меню</span>
						<div class="cart-empty__btns">
							<button
								v-if="currentBasket?.collective"
								@click="togglePopup('deleteBasket')"
								class="cart-wr__btn cart-empty__btn-exit"
							>
								Выйти из корзины
							</button>
							<button
								@click="redirectBasedOnTimeRange(currentBasket?.timeRange || 'lunch')"
								class="cart-wr__btn"
							>
								Перейти в меню
							</button>
						</div>
					</div>
				</div>
				<div
					class="cart-adaptive-total"
					v-if="isMyOrder ? myCartDetails.sum : cartSumm"
				>
					<div
						class="cart-adaptive-total__top"
						v-if="currentBasket?.owner"
					>
						<div class="cart-adaptive-total-persons">
							<div class="cart-adaptive-total-persons__label">Количество приборов:</div>
							<Counter
								:value="+personsCount"
								:max="+maxPersonsCount"
								@change="personsChange"
							/>
						</div>
					</div>
					<div class="cart-adaptive-total__bottom">
						<div class="cart-adaptive-total-text-wrap">
							<div
								v-if="cartDeliverySum && currentBasket?.owner"
								class="cart-adaptive-total-text-block"
							>
								<span class="cart-adaptive-total-text-block__gray">Стоимость доставки:</span>
								<span class="cart-adaptive-total-text-block__gray">{{
										addThousandSeparator(cartDeliverySum)
									}} ₽</span>
							</div>
							<div
								v-else-if="currentBasket?.owner"
								class="cart-adaptive-total-text-block"
							>
								<span class="cart-adaptive-total-text-block__gray">Стоимость доставки:</span>
								<span class="cart-adaptive-total-text-block__gray">бесплатно</span>
							</div>
							<div
								class="cart-adaptive-total-text-block"
								v-if="isMyOrder ? myCartDetails.weight : cartTotalWeight"
							>
								<span class="cart-adaptive-total-text-block__gray">Общий вес</span>
								<span class="cart-adaptive-total-text-block__gray">{{
										addThousandSeparator(isMyOrder ? myCartDetails.weight : cartTotalWeight)
									}} гр</span>
							</div>
							<div
								class="cart-adaptive-total-text-block"
								v-if="isMyOrder ? myCartDetails.kkal : cartKkal"
							>
								<span class="cart-adaptive-total-text-block__gray">Ккал</span>
								<span class="cart-adaptive-total-text-block__gray">{{
										addThousandSeparator(isMyOrder ? myCartDetails.kkal : cartKkal)
									}} Ккал
								</span>
							</div>
							<div
								class="cart-adaptive-total-text-block"
								v-if="currentBasket?.collective && currentBasket?.owner && myCartDetails.sum"
							>
								<span class="cart-adaptive-total-text-block__gray">Мой заказ</span>
								<span class="cart-adaptive-total-text-block__gray">{{
										addThousandSeparator(myCartDetails.sum)
									}} ₽</span>
							</div>
							<div
								v-if="cartBalls && currentBasket?.owner"
								class="cart-adaptive-total-text-block"
							>
								<span class="cart-adaptive-total-text-block__green">Баллов начислится:</span>
								<span class="cart-adaptive-total-text-block__green-bonus">
									+{{ addThousandSeparator(cartBalls) }}
									<img
										src="@/assets/img/icons/bonus.svg"
										alt="Bonus"
									>
								</span>
							</div>
							<div
								class="cart-adaptive-total-text-block"
								v-if="isMyOrder ? myCartDetails.sum : cartSumm"
							>
								<span class="cart-adaptive-total-text-block__result">Итого</span>
								<span class="cart-adaptive-total-text-block__result-summ">{{
										addThousandSeparator(isMyOrder ? myCartDetails.sum : cartSumm)
									}} ₽</span>
							</div>
						</div>

						<button
							v-if="cartSumm && currentBasket?.owner"
							class="button button_red cart-adaptive-total__btn"
							@click="openConfirmOrderingPopup"
						>
							Перейти к оформлению
						</button>
						<button
							v-else-if="!currentBasket?.owner && !isDone"
							@click="toggleUserFinishedPopup"
							class="button button_red cart-adaptive-total__btn"
						>
							Завершить сборку
						</button>
						<span
							v-else-if="!currentBasket?.owner && isDone"
							class="confirm__finished"
						>
							Вы закончили сборку
						</span>
					</div>
				</div>
			</template>
		</div>
		<div class="cart-right">
			<CartConfirm
				@openUserFinishedPopup="toggleUserFinishedPopup"
				@openConfirmOrderingPopup="openConfirmOrderingPopup"
			/>
		</div>
		<ConfirmationPopup
			:visible="popups.deleteBasket"
			:title="'Вы уверены, что хотите выйти из коллективной корзины?'"
			:subtitle="'Придётся очистить корзину. Отменить переход будет нельзя'"
			@closePopup="togglePopup('deleteBasket')"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="togglePopup('deleteBasket')"
				>Отмена
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="getDeleteAction"
				>Выйти из корзины
				</button>
			</template>
		</ConfirmationPopup>
		<ConfirmationPopup
			:visible="showUserFinishedPopup"
			:title="'Вы уверены, что хотите завершить сборку заказа?'"
			@closePopup="toggleUserFinishedPopup"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="toggleUserFinishedPopup"
				>Отмена
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="handleUserFinished"
				>Подтвердить
				</button>
			</template>
		</ConfirmationPopup>
		<ConfirmationPopup
			:visible="popups.waitUsers"
			:title="'Не все участники завершили сборку заказа'"
			:subtitle="'Дождитесь когда все участники завершат сборку заказа или перейдите к оформлению'"
			@closePopup="togglePopup('waitUsers')"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="goOrdering(true)"
				>Перейти к оформлению
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="togglePopup('waitUsers')"
				>Подождать
				</button>
			</template>
		</ConfirmationPopup>
		<ConfirmationPopup
			:visible="popups.goOrdering"
			:title="'Завершить сборку?'"
			@closePopup="togglePopup('goOrdering')"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="togglePopup('goOrdering')"
				>Отмена
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="goOrdering(true)"
				>Да
				</button>
			</template>
		</ConfirmationPopup>
	</div>
</template>

<script>
import 'swiper/css/swiper.min.css'
import CartConfirm from '@/components/cart/CartConfirm.vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import Counter from '@/components/ui/Counter.vue'
import WordEnding from '@/mixins/returnWordEnding.js'
import RestaurantInfo from '@/components/block/Menu/RestaurantInfo.vue'
import {mapActions, mapGetters} from 'vuex';
import {addThousandSeparator, redirectBasedOnTimeRange} from '@/helpers';
import search from "@/mixins/search.js";
import CartProducts from '@/components/cart/CartProducts.vue'
import {useYandexMetrika, useUserCompleteTheBuild} from "@/composables";
import BlockPreloader from "@/components/ui/BlockPreloader.vue";
import CartHeader from "@/components/cart/CartHeader.vue";

export default {
	setup()
	{
		const {yandexMetrikaGoal} = useYandexMetrika();
		const {
			showUserFinishedPopup,
			toggleUserFinishedPopup,
			handleUserFinished
		} = useUserCompleteTheBuild();

		return {
			yandexMetrikaGoal,
			showUserFinishedPopup,
			toggleUserFinishedPopup,
			handleUserFinished
		};
	},
	components:
		{
			CartHeader,
			BlockPreloader,
			RestaurantInfo,
			CartConfirm,
			Counter,
			Swiper,
			SwiperSlide,
			CartProducts,
			ConfirmationPopup: () => import('@/components/popup/ConfirmationPopup.vue'),
		},
	mixins: [WordEnding, search],
	data()
	{
		return {
			popups: {
				deleteBasket: false,
				waitUsers: false,
				goOrdering: false,
			},
		}
	},
	computed:
		{
			...mapGetters({
				myCartDetails: 'getMyCartDetails',
				currentBasket: 'getCurrentBasket',
				cartTotalWeight: 'getCartTotalWeight',
				cartDeliverySum: "getCartDeliverySum",
				cartSumm: 'getCartSumm',
				cartBalls: 'getCartBalls',
				cartKkal: 'getCartKkal',
				personsCount: 'getPersonsCount',
				maxPersonsCount: 'getMaxPersonsCount',
				isMyOrder: 'getIsMyOrder',
				cartProductsCount: 'getCartProductsCount',
				cartLoading: 'getCartLoading',
				basketUsers: 'getBasketUsers',
				isDone: 'getDone',
				user: 'getUser',
				curRest: 'order/currentRestaurant',
			}),
			cartProductsShow()
			{
				const hasOwnerAndCollective = this.currentBasket?.owner && this.currentBasket?.collective;
				const isOrderCount = this.isMyOrder ? this.myCartDetails.count : this.cartProductsCount;

				return hasOwnerAndCollective || isOrderCount
			}
		},
	methods:
		{
			...mapActions({
				fetchChangeDeviceQuantity: 'fetchChangeDeviceQuantity',
				deleteBasketType: 'deleteBasketType',
				deleteUser: 'deleteUser',
				resetCartToDefault: 'resetCartToDefault',
				getCart: 'getCart',
			}),
			addThousandSeparator,
			redirectBasedOnTimeRange,
			togglePopup(value)
			{
				this.popups[value] = !this.popups[value];
			},
			async openConfirmOrderingPopup()
			{
				try
				{
					//Если человек не авторизован, то открывается авторизация
					const success = await this.$store.dispatch("isLogged");

					if (!success) return this.$store.commit('openPhoneAuthPopup');

					//Если выбрана основная корзина, то сразу перекидываем на страницу оплаты
					const basketId = this.$cookie.get('basketId');

					if (basketId === "main") return this.goOrdering(true);

					if (this.checkUserFinished()) return this.togglePopup('waitUsers');

					this.togglePopup('goOrdering');
				} catch (error)
				{
					throw new Error(error)
				}
			},
			checkUserFinished()
			{
				return this.basketUsers.find(user => user?.done === false);
			},
			async getDeleteAction()
			{
				if (this.currentBasket?.owner)
				{
					await this.deleteBasketType(this.currentBasket?.id)
				} else
				{
					await this.deleteUser({
						userId: this.user?.ID,
						cartTypeId: this.currentBasket?.id
					});
				}

				await this.resetCartToDefault();

				await this.getCart();

				redirectBasedOnTimeRange(this.cartTimeRange);

				this.togglePopup('deleteBasket');
			},
			async goOrdering(params)
			{
				if (params)
				{
					await this.yandexMetrikaGoal(45209871, `oformit_korzina`);
					this.$router.push('/order');
				}
			},
			async personsChange(count)
			{
				await this.fetchChangeDeviceQuantity(count)
			},
		},
}
</script>

<style lang="scss">
.cart-empty__descr
{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cart__products
{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.cart-adaptive-total-text-wrap
{
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
}

.cart-adaptive-total-text-block
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cart-adaptive-total-text-block__gray
{
	color: $greyLightText;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.cart-adaptive-total-text-block__green
{
	color: $green;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}

.cart-adaptive-total-text-block__green-bonus
{
	display: flex;
	place-items: center;
	padding: 2px 4px;
	border-radius: 4px;
	color: white;
	background-color: $green !important;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;

	img
	{margin-left: 4px;}
}

.cart-adaptive-total-text-block__result
{
	color: $textColor;
	font-size: 20px;
	font-weight: 700;
	line-height: 32px;
}

.cart-adaptive-total-text-block__result-summ
{
	color: $textColor;
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
}

.cart-left
{
	display: flex;
	flex-direction: column;
	gap: 32px;
	flex: 70%;
}

.cart-left .cart-left__preloader
{
	background-color: white;
	height: 300px;
}

.cart-right
{
	display: none;
	flex-shrink: 0;
	flex: 30%;

	@media(min-width: 1440px)
	{
		display: block;
		max-width: 372px;
	}
}

.cart-wr__loading
{opacity: 0.5;}

.cart-wr
{
	display: flex;
	align-items: flex-start;
	width: 100%;
	gap: 50px;
}

.cart-adaptive-total-persons
{
	display: flex;
	justify-content: space-between;
	margin-bottom: 27px;
}

.cart-adaptive-total-persons__label
{
	font-family: $mainFontBold;
	font-size: 16px;
	line-height: 100%;
	color: $textColor;
}

.cart-adaptive-total__btn
{
	margin-bottom: 0;
	font-family: $mainFontBoldNew;
	border-radius: 8px;
	margin-top: 24px;
	font-size: 18px;
	line-height: 24px;
	width: 100%;
	height: 56px;
	user-select: none;
	cursor: pointer;
}

.cart-wr__btn
{
	border: none;
	width: 242px;
	padding: 18px 0;

	text-align: center;

	font-family: $mainFontBoldNew;
	font-size: 16px;
	line-height: 20px;

	border-radius: 8px;

	color: $green;
	background-color: #E3F3E4;
	transition: .3s;

	&:hover
	{
		background-color: $green;
		color: white;
	}
}

.cart-empty
{
	display: flex;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
	padding-top: 52px;
	margin-bottom: 24px;

}

.cart-empty__title
{
	font-family: $mainFontBold;
	text-align: center;
	font-size: 28px;
	line-height: 120%;
	color: $textColor;
	letter-spacing: .01px;
	padding-top: 16px;
}

.cart-empty__subtitle
{
	display: inline-block;
	margin-top: 8px;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 24px;
	text-align: center;
	color: $greyLightText;
}

.cart-empty__btns
{
	display: flex;
	align-items: center;
	gap: 16px;
}

.cart__btns-wr
{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cart-empty__btn-exit
{
	background-color: $greyDelimiter;
	color: $textColor;

	&:hover
	{
		background-color: $textColor;
		color: $greyDelimiter;
	}
}

.cart-adaptive-total
{
	padding-top: 12px;
	border-top: 1px solid $greyBorder;

	@media(min-width: 1440px)
	{
		display: none;
	}
}

@media (max-width: 1024px)
{
	.cart-empty__title
	{
		font-size: 24px;
		line-height: 32px;
	}
	.cart-wr
	{display: block;}
	.cart-adaptive-hide
	{display: none;}
	.cart-adaptive-total-persons
	{
		display: flex;
		justify-content: space-between;
		margin-bottom: 27px;
	}
	.cart-adaptive-total-persons__label
	{
		font-family: $mainFontBold;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		color: $textColor;
	}
}

@media (max-width: 767px)
{
	.cart__btns-wr
	{display: none;}
	.cart-empty__btn-exit
	{display: none;}
}

@media (max-width: 576px)
{
	.cart-adaptive-total-persons__label
	{max-width: 161px;}
	.cart__restaurant-info
	{display: none;}
	.cart-empty
	{
		margin-bottom: 32px;
		padding-top: 24px;
	}
}
</style>
